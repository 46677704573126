import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { PickupModel } from "../../../app/data/pickups/models";
import {
  getPickups,
  pickupsSelector
} from "../../../slices/pickups/pickupsSlice";
import { StopStatus } from "../../../app/data/common/constants";
import Loading from "../../../components/loading/loading";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import "./pickupsList.scss";
import "./../../../styles/text.scss";
import "./../../../styles/blocks.scss";

interface PickupsListProps {
  onPickupsAssigned?: (result: boolean) => void;
};

const PickupsList: React.FC<PickupsListProps> = ({ onPickupsAssigned, ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pickupsState = useSelector(pickupsSelector);

  useEffect(() => {
    dispatch(getPickups());
  }, [dispatch]);

  useEffect(() => {
    onPickupsAssigned && onPickupsAssigned(pickupsState.pickups.length > 0);
  }, [pickupsState.pickups, onPickupsAssigned]);

  return (
    <div className="xgs-pickups-list">
      {pickupsState.pickups.length === 0 && pickupsState.requestStarted && pickupsState.requester === "GET_PICKUPS" && (
        <Loading
          isLoading={true}
          className="xgs-pickups-list__loading"
        />
      )}
      {pickupsState.pickups.length > 0 && (
        <>
          <div className="xgs-line-header"><span>Assigned Pickups</span></div>
          {pickupsState.pickups.map((pickup: PickupModel, index: number) => (
            <div
              className={`xgs-card__block ${pickup.status === StopStatus.COMPLETED ? "xgs-card__block--w-label" : ""}`}
              onClick={() => navigate(`/0/pickup-details/${pickup.pickupNumber}`)}
              key={"pickup-" + index}
            >
              {pickup.status === StopStatus.COMPLETED && (
                <div className="xgs-card__block__label xgs-card__block__label--completed">
                  Completed
                </div>
              )}
              <div className="xgs-card__line xgs-card__numbers">
                <div className="xgs-card__numbers__item">
                  <div className="xgs-card__numbers__item__value">{pickup.pickupNumber || "N/A"}</div>
                  Pickup #
                </div>
              </div>
              <div className="xgs-card__line xgs-card__totals">
                <div className="xgs-card__totals__item">
                  <div className="xgs-card__totals__item__icon">
                    <XGSIcon
                      icon={XGSIcons.faBoxes}
                    />
                  </div>
                  <div className="xgs-card__totals__item__text">
                    <span>{pickup.itemsCount || "-"}</span><br/>
                    Item{pickup.itemsCount > 1 ? "s" : ""}
                  </div>
                </div>
                <div className="xgs-card__totals__item">
                  <div className="xgs-card__totals__item__icon">
                    <XGSIcon
                      icon={XGSIcons.faWeight}
                    />
                  </div>
                  <div className="xgs-card__totals__item__text">
                    <span>{parseFloat(pickup.itemsTotalWeight.toFixed(2)) || "-"}</span><br/>
                    Pound{parseFloat(pickup.itemsTotalWeight.toFixed(2)) !== 1 ? "s" : ""}
                  </div>
                </div>
              </div>
              <div className="xgs-card__line" style={{ gap: "0 64px" }}>
                <div className="xgs-card__pair">
                  <span>{moment().format("MM/DD/YY")} {moment(pickup.readyTime, "HH:mm:ss").format("h:mm a")} - {moment(pickup.closeTime, "HH:mm:ss").format("h:mm a")}</span><br />
                  Time of Availability
                </div>
                <div className="xgs-card__pair">
                  <span>{pickup.shipper.name}</span><br />
                  Company
                </div>
              </div>
              <div className="xgs-card__pair">
                <span>{pickup.shipper.address.address1}{(pickup.shipper.address.address1 && pickup.shipper.address.city) ? ", " : ""}{pickup.shipper.address.city}</span><br />
                Pickup Address
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default PickupsList;
