import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { AddressModel, ItemModel } from "../common/models";
import { StopStatus } from "../common/constants";

interface PickupShipperModel {
  accountNumber: number;
  contacts: {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    allowSms: boolean;
  };
  address: AddressModel & {
    lat: number;
    lng: number;
  };
  name: string;
};

interface PickupConsigneeModel {
  address: AddressModel & {
    lan: number;
    lng: number;
  };
  name: string;
  phone: string;
  email: string;
};

export interface PickupModel {
  itemsCount: number;
  itemsTotalWeight: number;
  status: StopStatus;
  bolNumber: string;
  pickupNumber: string;
  poNumber: string;
  consignee: PickupConsigneeModel;
  shipper: PickupShipperModel;
  items: ItemModel[];
  readyTime: string;
  closeTime: string;
  specialInstructions: string;
  arrivalTime: string;
  reached: boolean;
};

export const PickupItemSchema: Yup.ObjectSchema<ItemModel> = Yup.object({
  id: Yup.string(),
  packageType: Yup.string().required(validationMessages.required),
  units: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .integer("The value must be a valid amount without decimal values")
    .positive(validationMessages.positive)
    .max(9999, validationMessages.max(9999))
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  yards: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid)
    .when("packageType", {
      is: "ROLL",
      then: (schema) => schema
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "1",
      then: (schema) => schema
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "3",
      then: (schema) => schema
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    }),
  weight: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .integer("The value must be a weight without decimal values")
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  length: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  width: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  height: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  diameter: Yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  ratingClass: Yup.string(),//.required(validationMessages.required),
  sidemark: Yup
    .string()
    .max(40, "The value must be no more than 40 characters"),
  notes: Yup.string().max(150, "The value must be no more than 150 characters"),
  description: Yup.string(),
  deleted: Yup.boolean(),
  commodityCode: Yup.string(),
  commodityCodeDescription: Yup.string(),
  status: Yup.string()
}).defined();

export interface ProbillModel {
  probillNumber: number | string;
  probillNumberConfirm: number | string;
};

export const PickupProbillSchema: Yup.ObjectSchema<ProbillModel> = Yup.object({
  probillNumber: Yup.number()
    .required(validationMessages.required)
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .min(10000000, validationMessages.notValid)
    .max(999999999, validationMessages.notValid)
    .integer(validationMessages.notValid)
    .typeError(validationMessages.notValid),
  probillNumberConfirm: Yup.number()
    .required(validationMessages.required)
    .transform((value, originalValue) => (originalValue === "" ? NaN : Number(originalValue)))
    .min(10000000, validationMessages.notValid)
    .max(999999999, validationMessages.notValid)
    .integer(validationMessages.notValid)
    .typeError(validationMessages.notValid)
    .test({
      name: "equalTo",
      exclusive: false,
      message: "Probill numbers do not match",
      params: {
        reference: Yup.ref("probillNumber"),
      },
      test(value: number) {
        return value === this.resolve(Yup.ref("probillNumber"));
      }
    })
}).defined();

export interface PickupCompleteModel {
  pickupNumber: string;
  probillNumber: number;
  completedTime: string;
  notes: string;
  items: ItemModel[];
};

export interface ArrivalAtPickupRequest {
  pickupNumber: string;
  arrivalTime: string;
  longitude: number;
  latitude: number;
};
