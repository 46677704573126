import React from "react";
import { UploadBolDocument } from "./uploadBol";
import "./../../../styles/common.scss";
import "./pickupProcess.scss";

interface PickupBolUploadProps {
  pickupNumber: string;
};

const PickupBolUpload: React.FC<PickupBolUploadProps> = ({pickupNumber}) => {
  return (
    <div className="xgs-pickup-process__upload-bol">
      <div className="xgs-pickup-process__text">
        Take a picture of the signed Bill of Lading with probill sticker.
        <UploadBolDocument pickupNumber={pickupNumber}/>
      </div>
    </div>
  );
};

export default PickupBolUpload;
