import { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PickupDetailsPath } from "./routes";
import { PickupModel } from "../../../app/data/pickups/models";
import { pickupsSelector } from "../../../slices/pickups/pickupsSlice";
import Tag from "../../../components/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import { StopStatus } from "../../../app/data/common/constants";
import { ItemModel } from "../../../app/data/common/models";
import StartPickupButton from "./startPickupButton";
import ArriveAtStop from "../../arrive-at-stop/arriveAtStop";
import LocationHeader from "../../location-header/locationHeader";
import { routeSelectSelector } from "../../../slices/route-select/routeSelectSlice";
import { ROUTES } from "../../../app/route/RoutesConfig";
import "../../stop-details/stopDetails.scss";
import "./pickupDetails.scss";
import "../pickups.scss";

export const PickupDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const routeSelectState = useSelector(routeSelectSelector);
  const pickupsState = useSelector(pickupsSelector);
  const [pickupDetails, setPickupDetails] = useState<PickupModel | undefined | null>(undefined);
  const [showArrivalDialog, setShowArrivalDialog] = useState(false);
  const pickupNumber = useMemo(() => params[PickupDetailsPath.pickupNumber] || null, [params]);

  const isItemPickedUp = (item: ItemModel) => {
    if (!pickupDetails) return false;
    // covers both locally updated during pickup process and API retrieved
    if (item.status === "PICKED_UP" || pickupDetails.status === StopStatus.COMPLETED) return true;
    return false;
  };

  useEffect(() => {
    if (!pickupsState.pickups || pickupsState.pickups.length === 0) return;
    const pickupData = pickupsState.pickups.find(pickup => pickup.pickupNumber === pickupNumber);
    setPickupDetails(pickupData ? pickupData : null);
  }, [pickupsState.pickups, pickupNumber]);

  useEffect(() => {
    if (pickupDetails?.reached === false) setShowArrivalDialog(true);
  }, [pickupDetails?.reached]);

  return (
    <div className="xgs-stop-details">
      {pickupDetails && (
        <>
          <LocationHeader
            address={pickupDetails.shipper.address}
            itemsCount={pickupDetails.itemsCount}
            totalWeight={pickupDetails.itemsTotalWeight}
            arrivalTime={pickupDetails.arrivalTime}
            type="PICKUP"
          />
          <div className="xgs-pickups__block xgs-pickup-details__shipper">
            <div className="xgs-pickup-details__shipper__name">
              {pickupDetails.shipper.name}
            </div>
          </div>
          <div className="xgs-pickups__block xgs-pickup-details__pickup">
            <div>
              <div className="xgs-pickups__pair xgs-pickups__pair--pickup-number"><span>Pickup #:</span> {pickupDetails.pickupNumber}</div>
              {pickupDetails.bolNumber && <div className="xgs-pickups__pair"><span>BOL #:</span> {pickupDetails.bolNumber}</div>}
              <div className="xgs-pickups__pair"><span>Items:</span> {pickupDetails.itemsCount}</div>
              <div className="xgs-pickups__pair"><span>Weight:</span> {pickupDetails.itemsTotalWeight} <span>lb{pickupDetails.itemsTotalWeight > 1 ? "s" : ""}</span></div>
              {pickupDetails.specialInstructions && <div className="xgs-pickups__pair"><span>Special Instructions:</span> {pickupDetails.specialInstructions}</div>}
            </div>
            {(pickupDetails.status !== "COMPLETED" && pickupDetails) && (
              <div>
                <StartPickupButton
                  pickupNumber={pickupDetails.pickupNumber}
                  continuePickup={!!pickupDetails.items.find(item => item.status === "PICKED_UP")}
                />
              </div>
            )}
          </div>
          <div className="xgs-pickup-details__items">
            <div className="xgs-pickups__block">
              {pickupDetails.items.map((item, index) => (
                <div
                  className="xgs-pickup-details__items__row"
                  key={"item-row-" + index}
                >
                  <div className="xgs-pickup-details__items__row__details">
                    <div className="xgs-pickups__pair"><span>Package:</span> {item.packageType}</div>
                    <div className="xgs-pickups__pair"><span>Weight:</span> {item.weight}</div>
                    {!!item.width && (item.width !== "-") && <div className="xgs-pickups__pair"><span>Width:</span> {item.width} <span>in</span></div>}
                    {!!item.length && (item.length !== "-") && <div className="xgs-pickups__pair"><span>Length:</span> {item.length} <span>in</span></div>}
                    {!!item.height && (item.height !== "-") && <div className="xgs-pickups__pair"><span>Height:</span> {item.height} <span>in</span></div>}
                    {!!item.yards && (item.yards !== "-") && <div className="xgs-pickups__pair"><span>Sq. Yards:</span> {item.yards}</div>}
                    {!!item.diameter && (item.diameter !== "-") && <div className="xgs-pickups__pair"><span>Diameter:</span> {item.diameter}</div>}
                    {!!item.ratingClass && (item.ratingClass !== "-") && <div className="xgs-pickups__pair"><span>Class:</span> {item.ratingClass}</div>}
                    {!!item.sidemark && (item.sidemark !== "-") && <div className="xgs-pickups__pair"><span>Sidemark:</span> {item.sidemark}</div>}
                    {!!item.notes && <div className="xgs-pickups__pair"><span>Notes:</span> {item.notes}</div>}
                  </div>
                  <Tag mods={{
                    color: isItemPickedUp(item) ? TagColor.GREEN : TagColor.GREY
                  }}>
                    {isItemPickedUp(item) ? "PICKED UP" : "NOT PICKED UP"}
                  </Tag>
                </div>
              ))}
            </div>
          </div>
          <ArriveAtStop
            manifestNumber={0}
            stopNumber={0}
            pickupNumber={pickupDetails.pickupNumber}
            type="PICKUP"
            address={pickupDetails.shipper.address.address1}
            show={showArrivalDialog}
            onArrive={() => setShowArrivalDialog(false)}
            onCancel={() => {
              setShowArrivalDialog(false);
              navigate(routeSelectState.selectedManifest ? `/${routeSelectState.selectedManifest}${ROUTES.route}` : ROUTES.manifests);
            }}
          />
        </>
      )}
      {(pickupDetails === null) && (
        <div className="xgs-stop-details__no-stop">Pickup not found</div>
      )}
    </div>
  );
};

export default PickupDetails;
