import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import PageLoading from "../page-loading/pageLoading";
import { ROUTES } from "../../app/route/RoutesConfig";
import { ManifestStatus } from "../../app/data/common/constants";
import {
  getRouteSummary,
  routeSummarySelector
} from "../../slices/route-summary/routeSummarySlice";
import {
  getRouteMapData,
  routeMapSelector
} from "../../slices/route-map/routeMapSlice";
import {
  getAllStopsDetails,
  stopDetailsSelector
} from "../../slices/stop-details/stopDetailsSlice";
import {
  getManifests,
  routeSelectSelector,
  setActiveManifest,
  setSelectedManifest
} from "../../slices/route-select/routeSelectSlice";
import {
  pickupsSelector,
  getPickups
} from "../../slices/pickups/pickupsSlice";
import { getClockRecords, getLocations, webClockSelector } from "../../slices/web-clock/webClockSlice";
import WebClockState from "../../slices/web-clock/webClockState";

export interface DataLoaderProps {
  dataToLoad?: {
    webClock: boolean;
  }
};

const DataLoader: React.FC<DataLoaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const routeSelectState = useSelector(routeSelectSelector);
  const routeMapState = useSelector(routeMapSelector);
  const routeSummaryState = useSelector(routeSummarySelector);
  const stopDetailsState = useSelector(stopDetailsSelector);
  const pickupsState = useSelector(pickupsSelector);
  const webClockState: WebClockState = useSelector(webClockSelector);

  const getManifestData = useCallback((manifestNumber: number) => {
    dispatch(getRouteSummary(manifestNumber));
    dispatch(getAllStopsDetails(manifestNumber));
    dispatch(getRouteMapData(manifestNumber));
  }, [dispatch]);

  useEffect(() => {
    if (routeSelectState.selectedManifest) {
      getManifestData(routeSelectState.selectedManifest);
    } else {
      dispatch(getManifests((manifests) => {
        const activeManifestObj = manifests.find((manifest) => manifest.status === ManifestStatus.ACTIVE);
        let currentManifest;
        if (activeManifestObj) {
          dispatch(setActiveManifest(activeManifestObj.manifestNumber));
          currentManifest = activeManifestObj.manifestNumber;
        }
        if (window.location.pathname !== ROUTES.manifests) {
          var pathArr = window.location.pathname.split("/");
          if (pathArr[1] && /^\d+$/.test(pathArr[1])) {
            // if we have manifest number in URL
            currentManifest = Number(pathArr[1]);
          }
        }
        if (currentManifest) {
          getManifestData(currentManifest);
          dispatch(setSelectedManifest(currentManifest));
        }
      }));
      dispatch(getPickups());
    }
  }, [dispatch, getManifestData, routeSelectState.selectedManifest]);

  const isLoading = routeSelectState.requestStarted 
  || routeMapState.requestStarted 
  || routeSummaryState.requestStarted 
  || (stopDetailsState.requestStarted && stopDetailsState.requester === "GET_ALL_STOPS_DETAILS")
  || webClockState.request["GET_CLOCK_RECORDS"]?.requestStarted
  || webClockState.request["GET_LOCATIONS"]?.requestStarted;

  const getMessage = () => (
    <>
      <div>{routeSelectState.requestStarted && "Loading Manifests..."}</div>
      <div>{pickupsState.requestStarted && "Loading Pickups..."}</div>
      <div>{routeSummaryState.requestStarted && "Loading Route..."}</div>
      <div>{stopDetailsState.requestStarted && (stopDetailsState.requester === "GET_ALL_STOPS_DETAILS") && "Loading Stops..."}</div>
      <div>{routeMapState.requestStarted && "Loading Map..."}</div>
      <div>{webClockState.request["GET_CLOCK_RECORDS"]?.requestStarted && "Loading Web Clock..."}</div>
      <div>{webClockState.request["GET_LOCATIONS"]?.requestStarted && "Loading Terminals..."}</div>
    </>
  );

  useEffect(() => {
    if (!props.dataToLoad?.webClock) return;

    dispatch(getClockRecords());
    dispatch(getLocations());
  }, [dispatch, props.dataToLoad?.webClock]);

  useEffect(() => {
    if (isLoading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isLoading]);

  return isLoading
    ? <PageLoading
        isLoading={true}
        message={getMessage()}
      />
    : null;
};

export default DataLoader;
