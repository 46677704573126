import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { ROUTES } from "../../../app/route/RoutesConfig";
import SlideInModal from "../../../components/slide-in-modal/slideInModal";
import { useAppDispatch } from "../../../hooks/storeHooks";
import SteppedProgress from "../../../components/stepped-progress/steppedProgress";
import Button, { ButtonThemes } from "../../../components/button/button";
import { PickupDocument } from "../../../app/data/stop-details/models";
import { PickupModel } from "../../../app/data/pickups/models";
import {
  completePickup,
  pickupsSelector,
  updatePickupTotals
} from "../../../slices/pickups/pickupsSlice";
import { routeSelectSelector } from "../../../slices/route-select/routeSelectSlice";
import PickupItemsConfirm from "./pickupConfirmItems";
import PickupProbill from "./pickupProbill";
import PickupChecklist from "./pickupChecklist";
import PickupBolUpload from "./pickupBolUpload";
import arrowLeftImg from "../../../images/arrow-left_gray.svg";
import arrowRightImg from "../../../images/arrow-right_white.svg";
import checkImg from "../../../images/check_white.svg";
import listImg from "../../../images/list_blue.svg";
import "./pickupProcess.scss";

interface PickupProcessProps {
  pickupNumber: string;
  show: boolean;
  onClose: () => void;
};

dayjs.extend(utc);

const PickupProcess: React.FC<PickupProcessProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pickupsState = useSelector(pickupsSelector);
  const routeSelectState = useSelector(routeSelectSelector);
  const [currentStep, setCurrentStep] = useState(0);
  const [showChecklist, setShowChecklist] = useState(false);
  const [pickupDetails, setPickupDetails] = useState<PickupModel | undefined>(undefined);
  const [probillNumberFieldValue, setProbillNumberFieldValue] = useState("");
  const [probillNumberConfirmFieldValue, setProbillNumberConfirmFieldValue] = useState("");

  const documentsQuery = useQuery({
    queryKey: ["pickup_docs"],
    queryFn: () => apiQueryAdapter.query<PickupDocument[]>(`/pickups/${props.pickupNumber}/documents`, "GET"),
    refetchInterval: 5000
  });

  const isStepCompleted = (step: number) => {
    const i = pickupsState.pickupsProbills.findIndex(pickupProbill => pickupProbill.pickupNumber === props.pickupNumber);
    if ((step === 0) && (pickupDetails?.items.filter(item => item.status === "PICKED_UP").length !== 0)) return true;
    if ((step === 1) && (i !== -1) && pickupsState.pickupsProbills[i].probillNumber) return true;
    return false;
  };

  const isNextDisabled = () => {
    const i = pickupsState.pickupsProbills.findIndex(pickupProbill => pickupProbill.pickupNumber === props.pickupNumber);
    if ((currentStep === 0) && (pickupDetails?.items.filter(item => item.status === "PICKED_UP").length === 0)) return true;
    if ((currentStep === 1) && ((i === -1) || !pickupsState.pickupsProbills[i].probillNumber)) return true;
    if ((currentStep === 2) && (documentsQuery.data?.length === 0)) return true;
    return false;
  };

  const onClose = () => {
    if (pickupDetails) {
      dispatch(updatePickupTotals({
        pickupNumber: pickupDetails.pickupNumber,
        updateType: "ALL"
      }));
    }
    props.onClose();
  };

  const onClickNext = () => {
    if (pickupDetails && currentStep === 0) dispatch(updatePickupTotals({
      pickupNumber: pickupDetails.pickupNumber,
      updateType: "PICKED_UP"
    }));
    setCurrentStep(currentStep + 1);
  };

  const onClickBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const onCompletePickup = () => {
    if (!pickupDetails) return;
    const i = pickupsState.pickupsProbills.findIndex(pickupProbill => pickupProbill.pickupNumber === props.pickupNumber);
    if (i === -1) return;
    dispatch(completePickup({
      pickupNumber: pickupDetails.pickupNumber,
      probillNumber: pickupsState.pickupsProbills[i].probillNumber,
      completedTime: dayjs.utc().format(),
      notes: "",
      items: pickupDetails.items.filter(item => item.status === "PICKED_UP")
    }, () => {
      toast.info("The pickup has been completed!");
      navigate(routeSelectState.selectedManifest ? `/${routeSelectState.selectedManifest}${ROUTES.route}` : "/");
    }, () => toast.error("Error completing pickup! Retry later or contact your manager.")));
  };

  useEffect(() => {
    if (!pickupsState.pickups || pickupsState.pickups.length === 0) return;
    const pickupData = pickupsState.pickups.find(pickup => pickup.pickupNumber === props.pickupNumber);
    pickupData && setPickupDetails(pickupData);
  }, [pickupsState.pickups, props.pickupNumber]);

  return (
    <SlideInModal
      show={props.show}
      title={`Complete the pickup: ${pickupDetails?.pickupNumber}`}
      onClose={() => onClose()}
      mods={{ "full-screen": true }}
    >
      {pickupDetails && (
        <>
          <div className="xgs-pickup-process">
            <div className="xgs-pickup-process__content" id="xgs-pickup-process__content--js">
              <div className="xgs-pickup-process__header">
                <div className="xgs-pickup-process__header__shipper">Shipper: {pickupDetails.shipper.name}</div>
                <div
                  className="xgs-pickup-process__header__checklist-button"
                  onClick={() => setShowChecklist(true)}
                >
                  <img src={listImg} alt="list" /> Show Checklist
                </div>
              </div>
              <div className="xgs-pickups__block xgs-pickups__block--flex">
                {pickupDetails.bolNumber && <div className="xgs-pickups__pair"><span>BOL:</span> {pickupDetails.bolNumber}</div>}
                {pickupDetails.poNumber && <div className="xgs-pickups__pair"><span>PO:</span> {pickupDetails.poNumber}</div>}
                <div className="xgs-pickups__pair"><span>Items:</span> {pickupDetails.itemsCount}</div>
                <div className="xgs-pickups__pair"><span>Weight:</span> {pickupDetails.itemsTotalWeight} <span>lb{pickupDetails.itemsTotalWeight > 1 ? "s" : ""}</span></div>
                <div className="xgs-pickups__pair"><span>Shipper Address:</span> {pickupDetails.shipper.address.address1.toLowerCase().capitalize()}, {pickupDetails.shipper.address.city.toLowerCase().capitalize()}, {pickupDetails.shipper.address.state} {pickupDetails.shipper.address.postalCode}</div>
              </div>
              <div className="xgs-pickup-process__steps" id="xgs-pickup-process__steps--js">
                <SteppedProgress
                  steps={[
                    {
                      title: `Confirm picked up items ${isStepCompleted(0) && (currentStep !== 0) ? " — Done" : ""}`,
                      completed: isStepCompleted(0),
                      details:
                        <PickupItemsConfirm
                          pickupNumber={props.pickupNumber}
                          shipperPostalCode={pickupDetails?.shipper.address.postalCode || ""}
                          items={pickupDetails?.items || []}
                        />
                    },
                    {
                      title: `Enter probill number ${isStepCompleted(1) && (currentStep !== 1) ? " — Done" : ""}`,
                      completed: isStepCompleted(1),
                      details:
                        <PickupProbill
                          pickupNumber={props.pickupNumber}
                          probillNumber={probillNumberFieldValue}
                          probillNumberConfirm={probillNumberConfirmFieldValue}
                          onChangeProbillNumber={(v) => setProbillNumberFieldValue(v)}
                          onChangeProbillNumberConfirm={(v) => setProbillNumberConfirmFieldValue(v)}
                        />
                    },
                    {
                      title: "Upload the Bill of Lading",
                      completed: isStepCompleted(2),
                      details:
                        <PickupBolUpload
                          pickupNumber={pickupDetails?.pickupNumber}
                        />
                    }
                  ]}
                  current={currentStep}
                  vertical
                />
              </div>
            </div>
            <div className="xgs-pickup-process__buttons">
              {currentStep > 0
                ? (
                  <Button
                    type="button"
                    theme={ButtonThemes.gray}
                    onClick={onClickBack}
                  >
                    <img src={arrowLeftImg} alt="left" /> Back
                  </Button>
                ) : (
                  <Button
                    type="button"
                    theme={ButtonThemes.gray}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                )
              }
              {(currentStep === 2)
                ? (
                  <Button
                    type="button"
                    theme={ButtonThemes.blue}
                    onClick={onCompletePickup}
                    disabled={isNextDisabled()}
                    spinner={pickupsState.requestStarted && pickupsState.requester === "COMPLETE_PICKUP"}
                  >
                    <img src={checkImg} alt="check" /> Confirm
                  </Button>
                ) : (
                  <Button
                    type="button"
                    theme={ButtonThemes.blue}
                    onClick={onClickNext}
                    disabled={isNextDisabled()}
                  >
                    Next <img src={arrowRightImg} alt="right" />
                  </Button>
                )
              }
            </div>
          </div>
          <SlideInModal
            show={showChecklist}
            title="Pickup preparation checklist"
            onClose={() => setShowChecklist(false)}
          >
            <PickupChecklist
              bolNumber={pickupDetails.bolNumber || ""}
              poNumber={pickupDetails.poNumber || ""}
              consigneeLocation={`${pickupDetails.consignee.address.city}, ${pickupDetails.consignee.address.state} ${pickupDetails.consignee.address.postalCode}`}
              onClose={() => setShowChecklist(false)}
            />
          </SlideInModal>
        </>
      )}
    </SlideInModal>
  );
}

export default PickupProcess;
