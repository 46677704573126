import api, { ApiResponse } from "./../api";

class CommonService {
  private static instance: CommonService;
  private constructor() {}
  static getInstance(): CommonService {
    if (!CommonService.instance) {
      CommonService.instance = new CommonService();
    }
    return CommonService.instance;
  }

  public getFreightClasses = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/freight-classes?zip=${zip}`);
  };

  public getCC = async (): Promise<ApiResponse<any>> => {
    return await api.get("/rate-quote/commodity-codes");
  };
}

export default CommonService;
