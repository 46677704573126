import React from "react";
import Button, { ButtonThemes } from "../../../components/button/button";
import paperIcon from "../../../images/paper_blue.svg";
import listIcon from "../../../images/list_blue.svg";
import labelIcon from "../../../images/label_blue.svg";
import probillIcon from "../../../images/probill_blue.svg";
import "./pickupProcess.scss";

interface PickupChecklistProps {
  bolNumber: string;
  poNumber: string;
  consigneeLocation: string;
  onClose: () => void;
};

const PickupChecklist: React.FC<PickupChecklistProps> = (props) => {
  return (
    <div className="xgs-pickup-process__checklist">
      <div className="xgs-pickup-process__checklist__header">Pickup preparation checklist</div>
      <div>
        <div className="xgs-pickup-process__checklist__item">
          <div className="xgs-pickup-process__checklist__item__icon"><img src={paperIcon} alt="paper" /></div>
          <div>
            <strong>Collect the paper Bill of Lading (BOL)</strong> from the customer before moving further.
          </div>
        </div>
        <div className="xgs-pickup-process__checklist__item">
          <div className="xgs-pickup-process__checklist__item__icon"><img src={listIcon} alt="list" /></div>
          <div>
            <strong>Verify the pickup (PU) details</strong>, ensuring that the BOL number and destination address match the information on the paper BOL and the PO number.
            {props.bolNumber && (
              <div className="xgs-pickup-process__checklist__value xgs-pickup-process__checklist__value--bol">
                BOL #{props.bolNumber}
              </div>
            )}
            <div className="xgs-pickup-process__checklist__row">
              <div className="xgs-pickup-process__checklist__value xgs-pickup-process__checklist__value--location">
                Cons. location: {props.consigneeLocation}
              </div>
              {props.poNumber && (
                <div className="xgs-pickup-process__checklist__value">
                  PO #{props.poNumber}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="xgs-pickup-process__checklist__item">
          <div className="xgs-pickup-process__checklist__item__icon"><img src={labelIcon} alt="label" /></div>
          <div>
            <strong>Confirm that the customer has placed XGS labels</strong> on all items for the pickup. If any stickers are missing, apply stickers from the sticker book to each related item.
          </div>
        </div>
        <div className="xgs-pickup-process__checklist__item">
          <div className="xgs-pickup-process__checklist__item__icon"><img src={probillIcon} alt="probill" /></div>
          <div>
            <strong>Check if the paper Bill of Lading contains the XGS Probill number</strong> as shown on the shipping labels. If the Probill Number is not present, ask the customer to write it down along with their signature on the BOL document.
          </div>
        </div>
      </div>
      <Button
        type="button"
        theme={ButtonThemes.gray}
        onClick={props.onClose}
      >
        Close
      </Button>
    </div>
  );
}

export default PickupChecklist;
