import React, { useEffect, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { useQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import {
  ProbillModel,
  PickupProbillSchema
} from "../../../app/data/pickups/models";
import {
  pickupsSelector,
  setPickupProbill
} from "../../../slices/pickups/pickupsSlice";
import "./../../../styles/common.scss";
import "./pickupProcess.scss";
import Button, { ButtonThemes } from "../../../components/button/button";
import { commonSelector } from "../../../slices/common/commonSlice";
import barcodeImg from "../../../images/barcode_blue.svg";

let initialValues: ProbillModel = {
  probillNumber: "",
  probillNumberConfirm: ""
};

interface PickupProbillProps {
  // using this to store any entered values between steps (even incorrect ones)
  pickupNumber: string;
  probillNumber: string;
  probillNumberConfirm: string;
  onChangeProbillNumber: (v: string) => void;
  onChangeProbillNumberConfirm: (v: string) => void;
};

const PickupProbill: React.FC<PickupProbillProps> = ({
  onChangeProbillNumber,
  onChangeProbillNumberConfirm,
  probillNumber,
  probillNumberConfirm,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const pickupsState = useSelector(pickupsSelector);
  const pickupProbillRef = useRef<FormikProps<ProbillModel>>(null);
  const commonState = useSelector(commonSelector);

  const scannedProbillQuery = useQuery({
    queryKey: ["SCANNED_PROBILL", props.pickupNumber],
    queryFn: () => apiQueryAdapter.query<number | null>(`/pickups/${props.pickupNumber}/temporary-probill`, "GET"),
  });

  const onSubmit = () => {
    dispatch(setPickupProbill({
      pickupNumber: props.pickupNumber,
      probillNumber: pickupProbillRef.current?.values.probillNumber
    }));
  };

  const onProbillChange = (isValid?: boolean) => {
    dispatch(setPickupProbill({
      pickupNumber: props.pickupNumber,
      probillNumber: (isValid || (pickupProbillRef.current?.isValid && pickupProbillRef.current?.values.probillNumber))
        ? pickupProbillRef.current?.values.probillNumber
        : null
    }));
  };

  useEffect(() => {
    const i = pickupsState.pickupsProbills.findIndex(pickupProbill => pickupProbill.pickupNumber === props.pickupNumber);
    if (i === -1 || !pickupsState.pickupsProbills[i].probillNumber) return;
    pickupProbillRef.current?.setFieldValue("probillNumber", pickupsState.pickupsProbills[i].probillNumber);
    pickupProbillRef.current?.setFieldValue("probillNumberConfirm", pickupsState.pickupsProbills[i].probillNumber);
    onChangeProbillNumber(pickupsState.pickupsProbills[i].probillNumber.toString());
    onChangeProbillNumberConfirm(pickupsState.pickupsProbills[i].probillNumber.toString());
    // eslint-disable-next-line
  }, [pickupsState.pickupsProbills]);

  useEffect(() => {
    // for better UX
    // trigger validation if the driver previously entered value(s)
    // and returned to this step from another step
    if (!probillNumber && !probillNumberConfirm) return;
    pickupProbillRef.current?.setFieldValue("probillNumber", probillNumber);
    pickupProbillRef.current?.setFieldValue("probillNumberConfirm", probillNumberConfirm);
    setTimeout(() => {
      pickupProbillRef.current?.validateForm();
      pickupProbillRef.current?.setTouched({
        ...pickupProbillRef.current?.touched,
        probillNumber: true,
        probillNumberConfirm: true
      });
    }, 50);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // the probill number was obtained from the Android app (using API)
    if (!scannedProbillQuery.data) return;
    pickupProbillRef.current?.setFieldValue("probillNumber", scannedProbillQuery.data.toString());
    onChangeProbillNumber(scannedProbillQuery.data.toString())
    pickupProbillRef.current?.setFieldValue("probillNumberConfirm", scannedProbillQuery.data.toString());
    onChangeProbillNumberConfirm(scannedProbillQuery.data.toString())
    setTimeout(() => {
      pickupProbillRef.current?.setTouched({
        ...pickupProbillRef.current?.touched,
        probillNumber: true,
        probillNumberConfirm: true
      });
      pickupProbillRef.current?.validateForm();
      onProbillChange(true);
    }, 50);
    // eslint-disable-next-line
  }, [scannedProbillQuery.data])

  const onScanBarcode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const noAppTimeout = setTimeout(() => {
      window.removeEventListener("blur", onAppInstalled);
      toast.error("Your tablet does not have a scanning app installed! Please enter the probill number manually.");
    }, 500);

    const onAppInstalled = () => clearTimeout(noAppTimeout);
    window.location.href = `xgsdriver://barcode-scanner?token=${localStorage.getItem("xgs-driver-access-token")}&pickupId=${props.pickupNumber}`;
    window.addEventListener("blur", onAppInstalled);
  };

  return (
    <div className="xgs-pickup-process__probill-number">
      <div className="xgs-pickup-process__text">
        Enter the Probill number from the paper Bill of Lading.
      </div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={PickupProbillSchema}
        innerRef={pickupProbillRef}
        enableReinitialize
        validateOnMount
      >
        {(props: FormikProps<ProbillModel>) => (
          <Form>
            <div className="xgs-pickup-process__probill-number__form">
              <XGSFormInput
                type="text"
                name="probillNumber"
                label="Probill #:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                placeholder="Enter Probill"
                onChange={(e) => {
                  setTimeout(() => onProbillChange(), 50);
                  onChangeProbillNumber(e.currentTarget.value.replace(/\s/g, ""));
                  props.handleChange(e);
                }}
                value={probillNumber}
              />
              <XGSFormInput
                type="text"
                name="probillNumberConfirm"
                label="Probill #:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                placeholder="Enter again"
                onChange={(e) => {
                  setTimeout(() => onProbillChange(), 50);
                  onChangeProbillNumberConfirm(e.currentTarget.value.replace(/\s/g, ""));
                  props.handleChange(e);
                }}
                value={probillNumberConfirm}
              />
              {!commonState.offline && (
                <>
                  <div className="xgs-pickup-process__probill-number__form__text">or</div>
                  <Button
                    type="button"
                    theme={ButtonThemes.blueInverted}
                    onClick={onScanBarcode}
                  >
                    <img src={barcodeImg} alt="barcode" /> Scan Barcode
                  </Button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PickupProbill;
