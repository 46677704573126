import React, { useState } from "react";
import Button, { ButtonThemes } from "../../../components/button/button";
import PickupProcess from "../process/pickupProcess";
import pickupIcon from "../../../images/pickup_white.svg";

export interface StartPickupButtonProps {
  pickupNumber: string;
  continuePickup: boolean;
};

export const StartPickupButton: React.FC<StartPickupButtonProps> = ({ pickupNumber, continuePickup }) => {
  const [showModal, setShowModal] = useState(false);
  
  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <PickupProcess
          show={showModal}
          pickupNumber={pickupNumber}
          onClose={() => setShowModal(false)}
        />
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        theme={ButtonThemes.green}
        className="xgs-pickup-details__start-button"
      >
        <img src={pickupIcon} alt="pickup" />
        <div>{continuePickup ? "Continue" : "Start"} Pickup</div>
      </Button>
    </>
  );
};

export default StartPickupButton;