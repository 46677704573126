import { ApiBaseModel } from "../../app/data/common/models";
import { PickupModel } from "../../app/data/pickups/models";

export default interface PickupsState
  extends ApiBaseModel {
  pickups: PickupModel[];
  pickupsProbills: {
    pickupNumber: string;
    probillNumber: number;
  }[];
}

export const initialPickupsState: PickupsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null,
  pickups: [],
  pickupsProbills: []
};
