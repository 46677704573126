import React, { useEffect, useState } from "react";
import { StopDetails } from "../../../app/data/stop-details/models";
import "./stop-details-sticky-header.scss";

export type StopDetailsStickyHeaderProps = {
  stopDetails: StopDetails;
  consigneeIdx: number;
  probillIdx: number;
};

export const StopDetailsStickyHeader: React.FC<StopDetailsStickyHeaderProps> = ({ stopDetails, consigneeIdx, probillIdx }) => {
  const [show, setShow] = useState(false);
  const onScroll = () => {
    if (window.scrollY > 300) setShow(true);
    else setShow(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      className={
        "xgs-stop-details-sticky-header " +
        `xgs-stop-details-sticky-header--${show ? "show" : "hide"}`
      }
    >
      {stopDetails && (
        <span>
          {stopDetails.consignees[consigneeIdx].name}:&nbsp;
          {stopDetails.consignees[consigneeIdx].probills[probillIdx]?.probill?.toString()}&nbsp;
          ({stopDetails.consignees[consigneeIdx].probills[probillIdx]?.items})
        </span>
      )}
    </div>
  );
};
