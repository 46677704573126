
export default interface CommonState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  freightClasses: any[];
  commodityCodes: any[];
  offline: boolean;
};

export const initialCommonState: CommonState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  freightClasses: [],
  commodityCodes: [],
  offline: false
};
