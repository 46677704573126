import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import { useAppDispatch } from "../../hooks/storeHooks";
import XGSFormTextarea from "../../components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../components/button/button";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import XGSFormRadioGroup from "../../components/form/radio-group/xgsFormRadioGroup";
import {
  ExceptionModel,
  ExceptionFormModel,
  ExceptionSchema
} from "../../app/data/exceptions/models";
import { RouteSummaryModel } from "../../app/data/route-summary/models";
import {
  ConsigneeDetails,
  ProbillDetails,
  ProbillItem,
  StopDetails
} from "../../app/data/stop-details/models";
import { updateStopsStatuses } from "../../services/common/statusUpdater";
import {
  exceptionsSelector,
  resetExceptionsState,
  submitException
} from "../../slices/exceptions/exceptionsSlice";
import ExceptionsState from "../../slices/exceptions/ExceptionsState";
import {
  ACCEPTED_FORMATS,
  CONSIGNEE_REASONS,
  ITEM_REASONS,
  MAX_PHOTOS,
  MAX_SIZE,
  PROBILL_REASONS,
  REFUSE_OPTIONS
} from "./constants";
import "./exceptions.scss";

let initialValues: ExceptionFormModel = {
  reason: "",
  refused: undefined,
  comment: ""
};

interface AddExceptionModalProps {
  manifestNumber: number;
  source: string;
  probills: number[];
  itemId: string | null;
  onAddException: () => void;
  exception: ExceptionModel | null;
  stopNumber: number;
};

const AddExceptionModal: React.FC<AddExceptionModalProps> = ({
  manifestNumber,
  source,
  exception,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const exceptionFormRef = useRef<FormikProps<ExceptionFormModel>>(null);
  const exceptionsState: ExceptionsState = useSelector(exceptionsSelector);
  const [photos, setPhotos] = useState<any>([]);
  const [fileError, setFileError] = useState<string>("");

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS - photos.filter((obj: any) => !obj.deleted).length,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats (JPEG, PNG, WebP, GIF and BMP) are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos([...photos, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        deleted: false,
        comment: "",
        internalOnly: false
      }))]);
    }
  });

  const photosBlock = photos.map((photo: any, i: number) => (
    <div
      className={`xgs-exceptions__form__photos__item ${photo.deleted ? "xgs-exceptions__form__photos__item--deleted" : ""}`}
      key={`photo.name-${i}`}
    >
      <div className="xgs-exceptions__form__photos__item__image">
        <img
          src={photo.preview}
          alt="Preview"
        />
        <div
          className="xgs-exceptions__form__photos__item__image__control"
          onClick={() => removePhoto(i)}
        >
          <XGSIcon
            icon={XGSIcons.faTimes}
            className="xgs-exceptions__form__photos__item__icon"
          />
        </div>
      </div>
    </div>
  ));

  const reasons = () => {
    if (source === "CONSIGNEE") return CONSIGNEE_REASONS;
    if (source === "PROBILL") return PROBILL_REASONS;
    if (source === "ITEM") return ITEM_REASONS;
    return [];
  };

  const removePhoto = (i: number) => {
    let newArr = [...photos];
    newArr[i].deleted = true;
    setPhotos(newArr);
  };

  const updateCache = async (exception: ExceptionModel) => {
    const cache = await caches.open("xgs-driver-app-api-responses");

    const cachedRouteResponse = await cache.match(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}`);
    let routeResponseData: RouteSummaryModel;
    if (cachedRouteResponse) {
      routeResponseData = JSON.parse(await cachedRouteResponse.text());
    } else return;

    const cachedStopsResponse = await cache.match(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}/stops`);
    let stopsResponseData: StopDetails[];
    if (cachedStopsResponse) {
      stopsResponseData = JSON.parse(await cachedStopsResponse.text());
    } else return;

    const stopIndex = stopsResponseData.findIndex((stop: StopDetails) => stop.order === props.stopNumber);
    const consigneeIndex = stopsResponseData[stopIndex].consignees.findIndex((consignee: ConsigneeDetails) => consignee.probills.find((probillObj: ProbillDetails) => probillObj.probill === props.probills[0]));

    const newExceptionObj = {
      id: "",
      manifest: manifestNumber,
      level: source,
      reason: exception.reason,
      probills: exception.probills,
      comment: exception.comment,
      refused: exception.refused,
      photosCount: exception.photosCount,
      itemId: "",
      terminalException: exception.terminalException
    };

    // Update Exceptions
    routeResponseData.stops[stopIndex].exceptions = [
      ...routeResponseData.stops[stopIndex].exceptions,
      {
        id: "not-synced",
        level: source,
        probills: exception.probills
      }
    ];

    if (source === "CONSIGNEE") {
      stopsResponseData[stopIndex].consignees[consigneeIndex].exception = newExceptionObj;
    } else if (source === "PROBILL") {
      const probillIndex = stopsResponseData[stopIndex].consignees[consigneeIndex].probills.findIndex((probillObj: ProbillDetails) => probillObj.probill === exception.probills[0]);
      stopsResponseData[stopIndex].consignees[consigneeIndex].probills[probillIndex].exception = newExceptionObj;
    } else if (source === "ITEM" && exception.itemId) {
      const probillIndex = stopsResponseData[stopIndex].consignees[consigneeIndex].probills.findIndex((probillObj: ProbillDetails) => probillObj.probill === exception.probills[0]);
      const itemIndex = stopsResponseData[stopIndex].consignees[consigneeIndex].probills[probillIndex].itemDetails.findIndex((item: ProbillItem) => item.id === exception.itemId);
      stopsResponseData[stopIndex].consignees[consigneeIndex].probills[probillIndex].itemDetails[itemIndex].exception = {
        ...newExceptionObj,
        itemId: exception.itemId
      };
    } else return;

    // Update cache
    await cache.put(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}`, new Response(
      JSON.stringify(routeResponseData), {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ));
    await cache.put(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}/stops`, new Response(
      JSON.stringify(stopsResponseData), {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ));

    // Update Stops Statuses
    await updateStopsStatuses(manifestNumber, props.stopNumber);
  };

  const onSubmitException = (data: ExceptionFormModel) => {
    if (exception) return;
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    const fd = new FormData();
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }
    const preparedData = {
      comment: data.comment || "",
      ...(props.itemId && {itemIdentifier: props.itemId}),
      level: source,
      manifest: manifestNumber,
      probills: props.probills,
      reason: data.reason,
      refused: data.refused || false,
      stop: props.stopNumber,
      terminalException: (data.reason === "BUSINESS_CLOSED") || (data.reason === "CANNOT_DELIVER") || (data.reason === "CUSTOMER_REFUSED")
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(submitException(fd, () => {
      toast.info("The exception has been submitted successfully!");
      props.onAddException();
    }, () => {
      toast.info("The exception has been queued to be submitted when the Driver App is online!");
      updateCache({
        ...preparedData,
        id: "",
        itemId: preparedData.itemIdentifier || "",
        manifest: manifestNumber,
        photosCount: actualPhotos.length,
        terminalException: (data.reason === "BUSINESS_CLOSED") || (data.reason === "CANNOT_DELIVER") || (data.reason === "CUSTOMER_REFUSED")
      });
      props.onAddException();
    }));
  };

  const isPhotoRequired = () => {
    return exceptionFormRef.current?.values.reason === "ITEM_DAMAGED";
  };

  useEffect(() => {
    dispatch(resetExceptionsState());
    exceptionFormRef.current?.setValues(initialValues);
    if (exception) {
      exceptionFormRef.current?.setFieldValue("reason", exception.reason);
      exceptionFormRef.current?.setFieldValue("refused", exception.refused);
      exceptionFormRef.current?.setFieldValue("comment", exception.comment);
    }

    return () => {
      dispatch(resetExceptionsState());
    };
  }, [exception, dispatch]);

  return (
    <div className="xgs-exceptions">
      <Formik
        innerRef={exceptionFormRef}
        onSubmit={onSubmitException}
        initialValues={initialValues}
        validationSchema={ExceptionSchema}
        enableReinitialize
      >
        {(props: FormikProps<ExceptionFormModel>) => (
          <Form className="xgs-exceptions__form">
            <div className="xgs-exceptions__reason">
              <div className="xgs-form__label">Reason: <span>*</span></div>
              <XGSFormRadioGroup
                name="reason"
                items={reasons()}
                onValueChange={(v) => {
                  if (source === "CONSIGNEE") props.setFieldValue("refused", v === "CUSTOMER_REFUSED");
                  setTimeout(() => props.validateForm(), 50);
                }}
                disabled={!!exception}
              />
            </div>
            {source !== "CONSIGNEE" && (
              <div className="xgs-exceptions__refused">
                <div className="xgs-form__label">Did the customer refuse the item{source === "PROBILL" ? "s" : ""}? <span>*</span></div>
                <XGSFormRadioGroup
                  name="refused"
                  items={REFUSE_OPTIONS}
                  disabled={!!exception}
                />
              </div>
            )}
            <div className="xgs-exceptions__form__upload">
              <div className="xgs-form__label">Photos: {isPhotoRequired() ? <span>*</span> : ""}</div>
              {!exception && (
                <>
                  {(photos.filter((obj: any) => !obj.deleted).length < MAX_PHOTOS) && (
                    <div {...getRootProps({ className: "xgs-upload__area" })}>
                      <input {...getInputProps()} />
                      <XGSIcon
                        icon={XGSIcons.faCamera}
                        className="xgs-upload__area__icon"
                      />
                      <span className="blue-link">Tap to take or attach a photo</span>
                      {fileError && (
                        <span className="xgs-upload__area__error">
                          {fileError}
                        </span>
                      )}
                    </div>
                  )}
                </>
              )}
              {exception && (
                <div className="xgs-exceptions__details__photos">
                  {exception.photosCount > 0 ? `${exception.photosCount} photo${exception.photosCount > 1 ? "s" : ""}` : "no photos"}
                </div>
              )}
            </div>
            {(photos.length > 0) && (
              <div className="xgs-exceptions__form__photos">
                {photosBlock}
              </div>
            )}
            <div>
              <XGSFormTextarea
                name="comment"
                label="Comments:"
                required={false}
                rows={3}
                counter={60}
                className="xgs-exceptions__form__textarea"
                disabled={!!exception}
              />
            </div>
            {exceptionsState.requestFailed && (
              <XGSErrorMessage className="xgs-exceptions__form__error">{(exceptionsState.requestErrorCode === 401 || exceptionsState.requestErrorCode === 403) ? "The session has expired! Please refresh the page and try again." : (exceptionsState.requestError || "Error")}</XGSErrorMessage>
            )}
            {!exception && (
              <div className="xgs-exceptions__form__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  type="submit"
                  className="xgs-exceptions__form__submit"
                  spinner={exceptionsState.requestStarted && exceptionsState.requestCreator === "SUBMIT_EXCEPTION"}
                  disabled={!props.isValid || !props.dirty || (photos.filter((obj: any) => !obj.deleted).length === 0 && isPhotoRequired())}
                >
                  Submit
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddExceptionModal;
