import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { useAppDispatch } from "../../hooks/storeHooks";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import Button, { ButtonThemes } from "../../components/button/button";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import {
  routeSummarySelector,
  startRoute,
  updateRouteStatus,
  updateStopStatus
} from "../../slices/route-summary/routeSummarySlice";
import {
  routeSelectSelector,
  setActiveManifest,
  updateManifestStatus
} from "../../slices/route-select/routeSelectSlice";
import { pickupsSelector } from "../../slices/pickups/pickupsSlice";
import {
  ManifestStatus,
  StopStatus
} from "../../app/data/common/constants";
import { ManifestModel } from "../../app/data/route-select/models"
import {
  ProbillModel
} from "../../app/data/route-summary/models"
import AddressMapButton from "./addressMapButton";
import { RouteSummaryPath } from "./routes";
import { isRouteCompleted } from "../../services/route";
import { stopDetailsSelector } from "../../slices/stop-details/stopDetailsSlice";
import "./routeSummary.scss";
import "./../../styles/text.scss";

const RouteSummary: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const routeSummaryState = useSelector(routeSummarySelector);
  const routeSelectState = useSelector(routeSelectSelector);
  const pickupsState = useSelector(pickupsSelector);
  const stopDetailsState = useSelector(stopDetailsSelector);
  const [showStartConfirmation, setShowStartConfirmation] = useState(false);
  const [startStopOrder, setStartStopOrder] = useState(0);

  const getStopItems = (probills: ProbillModel[]) => {
    return probills.map((probill: ProbillModel) => probill.items).reduce((total: number, current: number) => total + current);
  };

  const getStopWeight = (probills: ProbillModel[]) => {
    const stopTotalWeight = probills.map((probill: ProbillModel) => probill.weight).reduce((total: number, current: number) => total + current);
    return parseFloat(stopTotalWeight.toFixed(2));
  };

  const manifestNumber = useMemo(() => {
    return params[RouteSummaryPath.manifestNumber] ? parseInt(params[RouteSummaryPath.manifestNumber], 10) : 0;
  }, [params]);

  const activeManifestIndex = routeSelectState.manifests ? routeSelectState.manifests.findIndex((manifest) => manifest.status === ManifestStatus.ACTIVE) : -1;

  const hasActivePickup = useMemo(() => pickupsState.pickups.some(pickup => pickup.status !== "COMPLETED"), [pickupsState]);

  const updateCache = async (_manifestNumber: number, status: ManifestStatus) => {
    const cache = await caches.open("xgs-driver-app-api-responses");
    const cachedRoutesResponse = await cache.match(process.env.REACT_APP_API_BASE_URL + "/drivers/routes");
    let routesResponseData: ManifestModel[];
    if (cachedRoutesResponse) {
      routesResponseData = JSON.parse(await cachedRoutesResponse.text());
    } else return;
    if (routesResponseData.length > 0) {
      const i = routesResponseData.findIndex(manifest => manifest.manifestNumber === _manifestNumber);
      if (i !== -1) {
        routesResponseData[i].status = status;
      }
    }
    // Update cache
    await cache.put(process.env.REACT_APP_API_BASE_URL + "/drivers/routes", new Response(
      JSON.stringify(routesResponseData), {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ));
  };

  useEffect(() => {
    if (!routeSummaryState.route) return;
    if (routeSummaryState.requester === "SUMMARY" && routeSummaryState.requestStarted) return;
    if (routeSelectState.manifests.length === 0) navigate("/");
    const manifestIndex = routeSelectState.manifests.findIndex((manifest) => manifest.manifestNumber === manifestNumber);
    if (manifestIndex === -1) return;
    if (routeSelectState.manifests[manifestIndex]?.status === ManifestStatus.COMPLETED) return;
    if (routeSummaryState.route.stops && (routeSummaryState.route.manifestNumber === manifestNumber) && isRouteCompleted(routeSummaryState.route.stops)) {
      if (navigator.onLine) {
        dispatch(updateManifestStatus({
          manifestNumber,
          status: ManifestStatus.COMPLETED
        }));
        if (routeSelectState.activeManifest === manifestNumber) dispatch(setActiveManifest(null));
        navigate("/");
      } else {
        updateCache(manifestNumber, ManifestStatus.COMPLETED);
      }
    }
  // eslint-disable-next-line
  }, [routeSummaryState.route]);

  return (
    <div className="xgs-route-summary">
      {routeSummaryState.requestSucceed && (routeSummaryState.requester === "SUMMARY" || routeSummaryState.requester === "START_ROUTE") && routeSummaryState.route && (
        <>
          {routeSummaryState.route.manifestNumber && (
            <>
              <div className="xgs-route-summary__numbers">
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.manifestNumber}</div>
                  Manifest #
                </div>
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.trailerNumber}</div>
                  Trailer #
                </div>
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.tractorNumber}</div>
                  Tractor #
                </div>
              </div>
              <div className="xgs-route-summary__totals">
                {!!routeSummaryState.route.stops && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faMapMarkerAlt}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{routeSummaryState.route.stops.length}</span><br/> Stop{routeSummaryState.route.stops.length > 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.routeLength && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faRuler}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{parseFloat(routeSummaryState.route.routeLength.toFixed(2))}</span><br/> Mile{parseFloat(routeSummaryState.route.routeLength.toFixed(2)) !== 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.numberOfItems && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faBoxes}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{routeSummaryState.route.numberOfItems}</span><br/> Item{routeSummaryState.route.numberOfItems > 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.weight && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faWeight}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{parseFloat(routeSummaryState.route.weight.toFixed(2))}</span><br/> Pound{parseFloat(routeSummaryState.route.weight.toFixed(2)) !== 1 ? "s" : ""}
                    </div>
                  </div>
                )}
              </div>
              {hasActivePickup && <div className="xgs-route-summary__pickup-message">You have pickups, scroll down to see them</div>}
              <div className="xgs-route-summary__list">
                {routeSummaryState.route.origin && (
                  <div className="xgs-route-summary__list__item xgs-route-summary__list__item--sc xgs-route-summary__list__item--sc-origin">
                    <div className="xgs-route-summary__list__item__box">
                      <div className="xgs-route-summary__list__item__info">
                        <div className="xgs-route-summary__list__item__number">
                          <XGSIcon
                            icon={XGSIcons.faWarehouse}
                            className="xgs-route-summary__list__item__icon"
                          />
                        </div>
                        <div className="xgs-route-summary__list__item__details">
                          <div className="xgs-route-summary__list__item__details__address">
                            {routeSummaryState.route.origin.address.address1}, {routeSummaryState.route.origin.address.city}, {routeSummaryState.route.origin.address.state}
                          </div>
                          <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>
                        </div>
                      </div>
                      <div className="xgs-route-summary__list__item__right-column">
                        <AddressMapButton
                          lat={routeSummaryState.route.origin.address.lat}
                          lng={routeSummaryState.route.origin.address.lng}
                        />
                      </div>
                    </div>
                    {routeSummaryState.route.stops && (
                      <div className="xgs-route-summary__list__item__sc-arrow"></div>
                    )}
                  </div>
                )}
                {routeSummaryState.route.stops && (
                  <>
                    {routeSummaryState.route.stops.map((stop, index) => (
                      <React.Fragment key={"item-" + index}>
                        {(stop.type !== "PICKUP") && (
                          <div
                            className={`
                              xgs-route-summary__list__item
                              ${((activeManifestIndex === -1) || (routeSelectState.manifests[activeManifestIndex].manifestNumber === manifestNumber)) ? " xgs-route-summary__list__item__box--clickable" : ""}
                              ${stop.status === StopStatus.COMPLETED ? " xgs-route-summary__list__item--past" : ""}
                              ${stop.status === StopStatus.ACTIVE ? " xgs-route-summary__list__item--active" : ""}
                            `}
                            onClick={() => {
                              if ((activeManifestIndex !== -1) && (routeSelectState.manifests[activeManifestIndex].manifestNumber !== manifestNumber)) return;
                              if ((stop.type === "DELIVERY") && (routeSummaryState.route?.status === ManifestStatus.PENDING) && (stop.status === StopStatus.UPCOMING)) {
                                setStartStopOrder(stop.order);
                                setShowStartConfirmation(true);
                              } else {
                                navigate(`/${manifestNumber}/stop-details/${stop.order}`);
                              }
                            }}
                          >
                            <div className="xgs-route-summary__list__item__box">
                              <div className="xgs-route-summary__list__item__info">
                                <div className="xgs-route-summary__list__item__number">
                                  {(stop.type === "DELIVERY") && <>{index + 1}</>}
                                </div>
                                <div className="xgs-route-summary__list__item__details">
                                  <div className="xgs-route-summary__list__item__details__address">
                                    {stop.address.address1}, {stop.address.city}, {stop.address.state} {(stop.address.additionalAddressLine && stopDetailsState.details?.[index]?.consignees.length === 1) ? `(${stop.address.additionalAddressLine})` : ""}
                                  </div>
                                  {(stop.type === "DELIVERY") && (
                                    <div className="xgs-route-summary__list__item__details__info">
                                      {stop.probills.length} Probill{stop.probills.length > 1 ? "s" : ""},&nbsp;
                                      {getStopItems(stop.probills)} Item{getStopItems(stop.probills) > 1 ? "s" : ""},&nbsp;
                                      {getStopWeight(stop.probills)} Pound{getStopWeight(stop.probills) !== 1 ? "s" : ""}
                                      {stop.exceptions && stop.exceptions.length > 0 && (
                                        <>
                                          ,&nbsp;<span className="xgs-route-summary__list__item__details__info__exceptions">{stop.exceptions.length} Exception{stop.exceptions.length > 1 ? "s" : ""}</span>
                                        </>
                                      )}
                                    </div>
                                  )}
                                  {stop.type === "PICKUP" && (
                                    <div className="xgs-route-summary__list__item__time">
                                      {/* <XGSIcon icon={XGSIcons.faClock}/>  */}
                                      {stop.pickupReadyTime?.toUiTimeFormat()} - {stop.pickupCloseTime?.toUiTimeFormat()}
                                    </div>
                                  )}
                                  {stop.type === "SERVICE_CENTER" && <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>}
                                </div>
                              </div>
                              <div>
                                <div className="xgs-route-summary__list__item__status">
                                  {stop.status === StopStatus.COMPLETED && <>Completed</>}
                                  {stop.status === StopStatus.ACTIVE && <>Active</>}
                                  {stop.status === StopStatus.UPCOMING && <>Upcoming</>}
                                </div>
                                <AddressMapButton
                                  lat={stop.address.lat}
                                  lng={stop.address.lng}
                                />
                              </div>
                            </div>
                            {((routeSummaryState.route?.stops?.length !== (index + 1)) || routeSummaryState.route?.destination) && <div className="xgs-route-summary__list__item__arrow"></div>}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                    <SlideInModal
                      title="Start of the route"
                      show={showStartConfirmation}
                      onClose={() => setShowStartConfirmation(false)}
                    >
                      <div className="xgs-route-summary__confirmation">
                        <div className="xgs-route-summary__confirmation__header">
                          Do you want to start this route from this stop?
                        </div>
                        <div className="xgs-route-summary__confirmation__buttons">
                          <Button
                            onClick={() => {
                              dispatch(startRoute(manifestNumber, startStopOrder, () => {
                                dispatch(updateRouteStatus(ManifestStatus.ACTIVE));
                                dispatch(updateStopStatus({
                                  order: startStopOrder,
                                  status: StopStatus.ACTIVE
                                }));
                                dispatch(updateManifestStatus({
                                  manifestNumber,
                                  status: ManifestStatus.ACTIVE
                                }));
                                dispatch(setActiveManifest(manifestNumber));
                              }, () => {
                                updateCache(manifestNumber, ManifestStatus.ACTIVE);
                              }));
                              setShowStartConfirmation(false);
                            }}
                            theme={ButtonThemes.blue}
                            className="xgs-route-summary__confirmation__buttons__button"
                          >
                            Yes, proceed
                          </Button>
                          <Button
                            onClick={() => setShowStartConfirmation(false)}
                            theme={ButtonThemes.gray}
                            className="xgs-route-summary__confirmation__buttons__button"
                          >
                            No, cancel
                          </Button>
                        </div>
                        <div className="xgs-route-summary__confirmation__note">
                          <strong>Note:</strong> this route and the selected stop will become active if you select "Yes, proceed".
                        </div>
                      </div>
                    </SlideInModal>
                  </>
                )}
                {!routeSummaryState.route.stops && (
                  <div className="xgs-route-summary__list__no-stops">
                    No assigned stops yet.
                  </div>
                )}
                {(pickupsState.pickups?.length > 0) && (
                  <div className="xgs-route-summary__pickups-list">
                    <div className="xgs-line-header">PICKUPS</div>
                    {pickupsState.pickups.map((pickup, index) => (
                      <React.Fragment key={"pickup-" + index}>
                        <div
                          className={`
                            xgs-route-summary__list__item xgs-route-summary__list__item__box--clickable
                            ${pickup.status === StopStatus.COMPLETED ? " xgs-route-summary__list__item--past" : ""}
                            ${pickup.status === StopStatus.ACTIVE ? " xgs-route-summary__list__item--active" : ""}
                          `}
                          onClick={() => navigate(`/${manifestNumber}/pickup-details/${pickup.pickupNumber}`)}
                        >
                          <div className="xgs-route-summary__list__item__box">
                            <div className="xgs-route-summary__list__item__info">
                              <div className="xgs-route-summary__list__item__number xgs-route-summary__list__item__number--pickup">
                                {pickup.pickupNumber}
                              </div>
                              <div className="xgs-route-summary__list__item__details">
                                <div className="xgs-route-summary__list__item__details__address">
                                  {pickup.shipper.address.address1}, {pickup.shipper.address.city}, {pickup.shipper.address.state} {pickup.shipper.address.additionalAddressLine ? `(${pickup.shipper.address.additionalAddressLine})` : ""}
                                </div>
                                <div className="xgs-route-summary__list__item__details__info">
                                  {pickup.itemsCount} Item{pickup.itemsCount > 1 ? "s" : ""},&nbsp;
                                  {pickup.itemsTotalWeight} Pound{pickup.itemsTotalWeight !== 1 ? "s" : ""}
                                </div>
                                <div className="xgs-route-summary__list__item__time">
                                  {pickup.readyTime.toUiTimeFormat()} - {pickup.closeTime.toUiTimeFormat()}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="xgs-route-summary__list__item__status">
                                {pickup.status === StopStatus.COMPLETED && <>Completed</>}
                                {pickup.status === StopStatus.ACTIVE && <>Active</>}
                                {pickup.status === StopStatus.UPCOMING && <>Upcoming</>}
                              </div>
                              <AddressMapButton
                                lat={pickup.shipper.address.lat}
                                lng={pickup.shipper.address.lng}
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {routeSummaryState.route.destination && (
                  <div className="xgs-route-summary__list__item xgs-route-summary__list__item--sc">
                    <div className="xgs-route-summary__list__item__box">
                      <div className="xgs-route-summary__list__item__info">
                        <div className="xgs-route-summary__list__item__number">
                          <XGSIcon
                            icon={XGSIcons.faWarehouse}
                            className="xgs-route-summary__list__item__icon"
                          />
                        </div>
                        <div className="xgs-route-summary__list__item__details">
                          <div className="xgs-route-summary__list__item__details__address">
                            {routeSummaryState.route.destination.address.address1}, {routeSummaryState.route.destination.address.city}, {routeSummaryState.route.destination.address.state}
                          </div>
                          <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>
                        </div>
                      </div>
                      <div className="xgs-route-summary__list__item__right-column">
                        <AddressMapButton
                          lat={routeSummaryState.route.destination.address.lat}
                          lng={routeSummaryState.route.destination.address.lng}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {routeSummaryState.requestFailed && routeSummaryState.requester === "SUMMARY" && (
        <XGSErrorMessage className="xgs-route-summary__failed">
          {routeSummaryState.requestFailReason || "Error. Please try again later."}
        </XGSErrorMessage>
      )}
    </div>
  );
}

export default RouteSummary;
