import React from "react";
import {ReactComponent as InfoIcon} from "../../images/info.svg";
import { cn } from "../../services/common/className";
import "./importantMessage.scss";

export enum ImportantMessageTheme {
  BLUE = "blue",
};

export interface ImportantMessageProps {
  children: any;
  theme: ImportantMessageTheme;
  mix?: string;
};

const ImportantMessage: React.FC<ImportantMessageProps> = (props) => {
  return (
    <div className={cn("xgs-important-message")({theme: props.theme}, props.mix)}>
      <InfoIcon        
        className="xgs-important-message__icon"
      />
      <span className="xgs-important-message__text">
        {props.children}
      </span>
    </div>
  );
};

export default ImportantMessage;