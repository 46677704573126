import api, { ApiResponse } from "../api";
import {
  ArrivalAtPickupRequest,
  PickupModel,
  PickupCompleteModel
} from "./models";

class PickupsService {
  private static instance: PickupsService;
  private constructor() {}
  static getInstance(): PickupsService {
    if (!PickupsService.instance) {
      PickupsService.instance = new PickupsService();
    }
    return PickupsService.instance;
  }

  public getPickups = async (): Promise<ApiResponse<PickupModel[]>> => {
    return await api.get("/drivers/routes/pickups");
  };

  public arriveAtPickup = async (
    request: ArrivalAtPickupRequest
  ): Promise<ApiResponse<any>> => {
    return await api.post("/drivers/routes/pickup/arrival", request);
  };

  public completePickup = async (
    pickupData: PickupCompleteModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/drivers/routes/complete/pickup", pickupData);
  };
}

export default PickupsService;
