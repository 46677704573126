import React, { DetailedHTMLProps, memo } from "react";
import Loading from "../loading/loading";
import "./button.scss";

export enum ButtonThemes {
  gray = "gray",
  white = "white",
  green = "green",
  red = "red",
  blue = "blue",
  blueInverted = "blue-inverted",
  orange = "orange"
}

export enum ButtonSizes {
  small = "small",
  medium = "medium",
  fullWidth = "full-width",
  auto = "auto"
}
export interface ButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  theme: ButtonThemes;
  size?: ButtonSizes;
  spinner?: boolean;
}

export const SmallButton: React.FC<ButtonProps> = ({className, ...props}) => {
  return (
    <Button className={`${className} xgs-btn-small`} {...props}>
      {props.children}
    </Button>
  );
}

const Button: React.FC<ButtonProps> = memo(({
  theme,
  size,
  spinner,
  className,
  ...props
}) => {
  return (
    <button
      className={`xgs-btn xgs-btn-${theme} ${size ? "xgs-btn-" + size : ""} ${className ? className : ""} ${spinner ? "xgs-btn--spinner" : ""}`}
      {...props}
      disabled={spinner ? true : (props.disabled || false)}
    >
      {spinner && (
        <div className="xgs-btn__spinner">
          <Loading isLoading={true} isSmall={true} />
        </div>
      )}
      {props.children}
    </button>
  );
});

export default Button;
