import "./alarmMessage.scss";
import React, { ReactNode } from "react";
import infoIcon from "../../images/info-circle_blue.svg";

export interface AlarmMessageProps {
  title: string,
  children: ReactNode
}

export const AlarmMessage: React.FC<AlarmMessageProps> = (props) => {
  return (
    <div className="xgs-alarm-message">
      <div className="xgs-alarm-message__title">
        <img src={infoIcon} alt="info"/>
        {props.title}
      </div>
      <div className="xgs-alarm-message__body">
        {props.children}
      </div>
    </div>
  )
}