import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useAppDispatch } from "../../../hooks/storeHooks";
import Button, { ButtonThemes } from "../../../components/button/button";
import { cn } from "../../../services/common/className";
import { ItemModel } from "../../../app/data/common/models";
import { freightItemValues } from "../../../app/data/common/freightItem";
import { PickupItemSchema } from "../../../app/data/pickups/models";
import {
  addItemToPickup,
  setItemStatus
} from "../../../slices/pickups/pickupsSlice";
import {
  getCommodityCodes,
  getFreightClasses
} from "../../../slices/common/commonSlice";
import FreightItemFormFields from "./freight-item/freightItemFormFields";
import plusIcon from "../../../images/plus_gray.svg";
import "./../../../styles/common.scss";
import "./pickupProcess.scss";

interface PickupItemsConfirmProps {
  pickupNumber: string;
  shipperPostalCode: string;
  items: ItemModel[];
};

let initialValues: ItemModel = freightItemValues;

const PickupItemsConfirm: React.FC<PickupItemsConfirmProps> = ({ pickupNumber, ...props }) => {
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [checkboxes, setCheckboxes] = useState<boolean[]>([]);
  const pickupItemRef = useRef<FormikProps<ItemModel>>(null);
  const dispatch = useAppDispatch();

  const onClickItem = (index: number) => {
    let newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes([...newCheckboxes]);
    if (newCheckboxes[index]) onSelectItem(index);
    if (!newCheckboxes[index]) onUnselectItem(index);
  };

  const onAddItem = (item: ItemModel) => {
    dispatch(addItemToPickup({
      pickupNumber,
      item
    }));
    const newCheckboxes = [...checkboxes];
    newCheckboxes.push(true);
    setCheckboxes(newCheckboxes);
    setShowAddItemForm(false);
  };

  const onSelectItem = (index: number) => {
    dispatch(setItemStatus({
      pickupNumber,
      index,
      status: "PICKED_UP"
    }));
  };

  const onUnselectItem = (index: number) => {
    dispatch(setItemStatus({
      pickupNumber,
      index,
      status: "NOT_PICKED_UP"
    }));
  };

  useEffect(() => {
    const newCheckboxes: boolean[] = [];
    for (const [index, item] of props.items.entries()) {
      newCheckboxes[index] = (item.status === "PICKED_UP");
    }
    setCheckboxes(newCheckboxes);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getFreightClasses(props.shipperPostalCode));
    dispatch(getCommodityCodes());
  }, [dispatch, props.shipperPostalCode]);

  return (
    <div className="xgs-pickup-process__confirm-items">
      <div className="xgs-pickup-process__text">
        Please, compare freight items with list below. Remove or add items if needed.
      </div>
      <div className="xgs-pickup-process__confirm-items__count">Items added {(checkboxes.filter(checkbox => checkbox)).length}/{props.items.length}</div>
      <div className="xgs-pickup-process__confirm-items__list">
        {props.items.map((item: ItemModel, index: number) => (
          <div
            className={cn("xgs-pickups__block xgs-pickups__block--flex no-select xgs-pickup-process__confirm-items__list__item")({ selected: checkboxes[index] })}
            onClick={() => onClickItem(index)}
            key={"pickup-item-" + index}
          >
            <div className="xgs-pickup-process__confirm-items__list__item__header-row">
              <input
                type="checkbox"
                name={"item-checkbox-" + index}
                value={(checkboxes[index] || false).toString()}
                checked={checkboxes[index]}
                onChange={() => onClickItem(index)}
              />
              {item.units} {item.packageType.toLowerCase().capitalize()}
            </div>
            <div className="xgs-pickups__pair"><span>Weight:</span> {item.weight || "-"} <span>lb{item.weight > 1 ? "s" : ""}</span></div>
            {!!item.width && (item.width !== "-") && <div className="xgs-pickups__pair"><span>Width:</span> {item.width} <span>in</span></div>}
            {!!item.length && (item.length !== "-") && <div className="xgs-pickups__pair"><span>Length:</span> {item.length} <span>in</span></div>}
            {!!item.height && (item.height !== "-") && <div className="xgs-pickups__pair"><span>Height:</span> {item.height} <span>in</span></div>}
            {!!item.yards && (item.yards !== "-") && <div className="xgs-pickups__pair"><span>Sq. Yards:</span> {item.yards}</div>}
            {!!item.diameter && (item.diameter !== "-") && <div className="xgs-pickups__pair"><span>Diameter:</span> {item.diameter}</div>}
            {!!item.ratingClass && (item.ratingClass !== "-") && <div className="xgs-pickups__pair"><span>Class:</span> {item.ratingClass}</div>}
            {!!item.sidemark && (item.sidemark !== "-") && <div className="xgs-pickups__pair"><span>Sidemark:</span> {item.sidemark}</div>}
            {!!item.notes && <div className="xgs-pickups__pair"><span>Notes:</span> {item.notes}</div>}
          </div>
        ))}
      </div>
      {!showAddItemForm && (
        <div className="xgs-pickup-process__confirm-items__add-button">
          <Button
            type="button"
            theme={ButtonThemes.gray}
            onClick={() => {
              setShowAddItemForm(true);
              setTimeout(() => {
                const el = document.getElementById("xgs-pickup-process__content--js");
                if (el) el.scrollTo({
                  left: 0,
                  top: 350,
                  behavior: "smooth"
                });
              }, 150);
            }}
            className="xgs-pickup-process__confirm-items__add-item-button"
          >
            <img src={plusIcon} alt="plus" /> Add new item
          </Button>
        </div>
      )}
      {showAddItemForm && (
        <div className="xgs-pickups__block xgs-pickup-process__confirm-items__add-item">
          <Formik
            onSubmit={onAddItem}
            initialValues={initialValues}
            validationSchema={PickupItemSchema}
            innerRef={pickupItemRef}
            enableReinitialize
          >
            {(props: FormikProps<ItemModel>) => (
              <Form>
                <FreightItemFormFields />
                <div className="xgs-pickup-process__confirm-items__add-item__buttons">
                  <Button
                    theme={ButtonThemes.blue}
                  >
                    Add Handling Unit
                  </Button>
                  <Button
                    type="button"
                    theme={ButtonThemes.gray}
                    onClick={() => setShowAddItemForm(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default PickupItemsConfirm;
