import React from "react";
import "./previewImage.scss";
import { useSelector } from "react-redux";
import { previewImageSelector, setImageForPreview } from "../../slices/preview-image/previewImageSlice";
import { useDispatch } from "react-redux";
import close from "./../../images/close.svg"

export const PreviewImage: React.FC<{}> = () => {
  const previewImageState = useSelector(previewImageSelector);
  const dispatch = useDispatch();

  if (!previewImageState.imageUrl) return null;

  return (
    <div 
      onClick={(e) => {
        e.stopPropagation()
        dispatch(setImageForPreview(null))
      }} 
      className="xgs-preview-image"
    >
      <img className="xgs-preview-image__close" onClick={(e) => dispatch(setImageForPreview(null))} src={close} alt="close"/>
      <img className="xgs-preview-image__image" onClick={(e) => dispatch(setImageForPreview(null))} src={previewImageState.imageUrl} alt="preview"/>
    </div>
  )
}