import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FORMATS, MAX_SIZE } from "../../exceptions/constants";
import bol_doc_img from "../../../images/bol_doc.svg";
import zoom_img from "../../../images/zoom.svg";
import "./uploadBol.scss";
import Button, { ButtonSizes, ButtonThemes } from "../../../components/button/button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { PickupDocument, PickupDocumentType } from "../../../app/data/stop-details/models";
import { useSelector } from "react-redux";
import { commonSelector } from "../../../slices/common/commonSlice";
import { useDispatch } from "react-redux";
import { setImageForPreview } from "../../../slices/preview-image/previewImageSlice";
import { toast } from "react-toastify";
import { AlarmMessage } from "../../../components/alarm-message/alarmMessage";
import cameraIcon from "../../../images/bol_camera.svg";

const MAX_PHOTOS = 5;

export interface UploadBolProps {
  pickupNumber: string;
  photos?: any[];
  setPhotos?: (val: any[]) => void;
}

export const UploadBolDocument: React.FC<UploadBolProps> = ({ setPhotos,...props}) => {
  const [fileError, setFileError] = useState("");
  const commonState = useSelector(commonSelector);
  const queryClient = useQueryClient();
  const [appInstalled, setAppInstalled] = useState(true);
  const dispatch = useDispatch();

  const offline = commonState.offline;

  const documentsQuery = useQuery({
    queryKey: ["pickup_docs"],
    queryFn: () => apiQueryAdapter.query<PickupDocument[]>(`/pickups/${props.pickupNumber}/documents`, "GET"),
    networkMode: "offlineFirst",
    refetchInterval: 5000
  })

  const updateCacheForDocumentInsert = async (pickupNumber: string, files: File[]) => {
    const cache = await caches.open("xgs-driver-app-api-responses");
    let docs: PickupDocument[];
    const cacheResponse = await cache.match(process.env.REACT_APP_API_BASE_URL + `/pickups/${pickupNumber}/documents`);
    if (cacheResponse) {
      docs = JSON.parse(await cacheResponse.text());
    } else return;
    files.forEach(() => {
      docs.push({
        id: Math.random() * 1000 + "",
        storageUrl: "",
        type: PickupDocumentType.BOL_DOCUMENT
      })
    })

    await cache.put(process.env.REACT_APP_API_BASE_URL + `/pickups/${props.pickupNumber}/documents`, new Response(
      JSON.stringify(docs), {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ));
  }

  const deleteDocumentQuery = useMutation({
    mutationFn: (documentId: string) => 
      apiQueryAdapter.query(`/pickups/documents/${documentId}`, "DELETE"),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["pickup_docs"]});
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
      console.error("Failed to delete document:", error);
    }
  });

   const addDocumentQuery = useMutation({
    mutationFn: (data: FormData) => 
      apiQueryAdapter.query(`/pickups/${props.pickupNumber}/documents`, "POST", {body: data}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["pickup_docs"]});
    },
    networkMode: "offlineFirst",
    onError: (error) => {
      if (commonState.offline) {
        toast.info("You are currently offline, documents will be uploaded in the background!")
        console.log("Document upload will be background synced");
      } else {
        toast.error(error.message || "Something went wrong");
        console.error("Failed to upload document:", error);
      }
    },
   })
  

  function openDocumentScannerApp(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    var appLink = `xgsdriver://document-scanner?token=${localStorage.getItem("xgs-driver-access-token")}&pickupId=${props.pickupNumber}`;
    e.stopPropagation();

    var noAppTimeout = setTimeout(function () {
      setAppInstalled(false)
      window.removeEventListener("blur", onAppInstalled)
    }, 1000);
    
    const onAppInstalled = () => {
      setAppInstalled(true);
      clearTimeout(noAppTimeout);
    }
    window.location.href = appLink;
  
    window.addEventListener("blur", onAppInstalled);
  }

  const {
      getRootProps,
      getInputProps,
    } = useDropzone({
      accept: ACCEPTED_FORMATS,
      maxSize: MAX_SIZE * 1048576,
      maxFiles: MAX_PHOTOS - (documentsQuery.data?.length || 0),
      disabled: MAX_PHOTOS - (documentsQuery.data?.length || 0) <= 0,
      onDrop: async (acceptedFiles, fileRejections) => {
        setFileError("");
        if (fileRejections?.length > 0) {
          fileRejections[0].errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
            }
            if (err.code === "file-invalid-type") {
              setFileError("Only images of certain formats (JPEG, PNG, WebP, GIF and BMP) are allowed!");
            }
            if (err.code === "too-many-files") {
              setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
            }
          });
          return;
        }
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append("files", file);
        })
        formData.append("data", JSON.stringify(acceptedFiles.map(() => ({ type: "BOL_DOCUMENT" }))))
        if (commonState.offline) {
          updateCacheForDocumentInsert(props.pickupNumber, acceptedFiles);
        }
        addDocumentQuery.mutate(formData);
      }
    });

  const removePhoto = async (e: any, id: string) => {
    e.stopPropagation();
    await deleteDocumentQuery.mutate(id);
  };

  // const hasBolDocument = documentsQuery.data && documentsQuery.data.some(doc => doc.type === PickupDocumentType.BOL_DOCUMENT);

  const documentQueryInProgress = documentsQuery.isLoading || addDocumentQuery.isPending || deleteDocumentQuery.isPending

  const onClickThumbnail = (imageUrl: string) => {
    dispatch(setImageForPreview(imageUrl));
  }

  const namedDocuments = documentsQuery.data ? documentsQuery.data.map((doc, idx) => {
    return Object.assign(doc, {
      preview: doc.storageUrl,
      photoName: `Scan ${String(idx+1).padStart(2, '0')}`
    })
  }) : []

  const showDocumentScannerButton = !offline && appInstalled;

  return (
    <div className="xgs-upload-bol">
      <div className="xgs-upload-bol__upload-buttons">
        {(!showDocumentScannerButton || offline) && (
          <div>
            <AlarmMessage title="Scan app is not responding">
              <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <div className="xgs-upload-bol__dropzone__button">
                  <div>{commonState.offline ? "Scanning app cannot be used when you are offline. " : "There is no scanning application installed on your tablet, or it is not functioning correctly."} Please click the button below to take a photo and attach images of each page of the Bill of Lading.</div>
                  <Button 
                    type="button"
                    theme={ButtonThemes.blue}
                    disabled={(MAX_PHOTOS - (documentsQuery.data?.length || 0) <= 0)}
                    className="xgs-upload-bol__upload-buttons__scan"
                    spinner={documentQueryInProgress}
                    size={ButtonSizes.small}
                  >
                    {!documentQueryInProgress && <img src={cameraIcon} alt=""/>  }
                    &nbsp;
                    Capture BOL Images
                  </Button>
                </div>
              </div>
            </AlarmMessage>
          </div>
        )}
        {showDocumentScannerButton && (
          <>
            <Button
              className="xgs-upload-bol__upload-buttons__scan"
              type="button" 
              disabled={MAX_PHOTOS - (documentsQuery.data?.length || 0) <= 0} 
              onClick={openDocumentScannerApp} 
              theme={ButtonThemes.blue}
              spinner={documentQueryInProgress}
            > 
              <img src={bol_doc_img} alt="doc icon"/> &nbsp; BOL Scans
            </Button>
          </>
        )}
      </div>
      <div className="xgs-upload-bol__photos">
        <div className="xgs-upload-bol__photos__container">
          {
            namedDocuments.map((photo) => (
              <div key={photo.photoName} className="xgs-upload-bol__photos__item">
                <div className="xgs-upload-bol__photos__item__name">{photo.photoName}</div>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__thumbnail" src={photo.preview} alt="preview"/>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__zoom" src={zoom_img} alt="zoom" />
                <Button disabled={offline} type="button" onClick={(e) => removePhoto(e, photo.id!)} theme={ButtonThemes.gray}>Delete</Button>
              </div>
            ))
          }
          {!namedDocuments.length && <div className="xgs-upload-bol__no-documents">No documents uploaded.</div>}
        </div>
        {fileError && (
        <div className="xgs-upload-bol__error">
          {fileError}
        </div>
      )}
      </div>
  </div>
  )
}
