import React, { useState, useEffect } from "react";
import checkIcon from "../../images/check_white.svg";
import plusIcon from "../../images/plus_gray.svg";
import minusIcon from "../../images/minus_gray.svg";
import { cn } from "../../services/common/className";
import "./steppedProgress.scss";

export interface SteppedProgressStep {
  completed: boolean;
  title?: string | React.ReactNode;
  details?: React.ReactNode;
  collapsible?: boolean;
  defaultOpened?: boolean;
};

interface SteppedProgressProps {
  className?: string;
  vertical?: boolean;
  steps: SteppedProgressStep[];
  current: number;
  showNextSteps?: boolean;
};

const SteppedProgress: React.FC<SteppedProgressProps> = ({ vertical, ...props }) => {
  const [collapsibleState, setCollapsibleState] = useState<boolean[]>([]);

  const toggleItem = (i: number) => {
    let newState = [...collapsibleState];
    newState[i] = !newState[i];
    setCollapsibleState(newState);
  };

  const lastStep = props.showNextSteps
    ? props.steps.length - 1
    : props.current + 1;

  useEffect(() => {
    if (!props.steps || props.steps.length === 0) return;
    let initialCollapsibleState = [];
    for (const [index, step] of props.steps.entries()) {
      if (step.defaultOpened) initialCollapsibleState[index] = true;
    }
    setCollapsibleState(initialCollapsibleState);
  }, [props.steps]);

  return (
    <ul className={cn("xgs-stepped-progress")({ vertical }, props.className)}>
      {props.steps.slice(0, lastStep).map((step: SteppedProgressStep, index: number) => (
        <li
          className={cn("xgs-stepped-progress__step")({
            completed: step.completed,
            current: index === props.current,
            collapsible: step.collapsible
          })}
          key={`progress-${index}`}
        >
          {step.completed && (index !== props.current) && (
            <img src={checkIcon} className="xgs-stepped-progress__step__icon" alt="check" />
          )}
          <div className="xgs-stepped-progress__step__header">
            <div className="xgs-stepped-progress__step__header__title">{step.title}</div>
            {step.collapsible && (
              <div
                className="xgs-stepped-progress__step__header__button no-select"
                onClick={() => toggleItem(index)}
              >
                {collapsibleState[index] && <img src={minusIcon} className="" alt="hide" />}
                {!collapsibleState[index] && <img src={plusIcon} className="" alt="show" />}
              </div>
            )}
          </div>
          {step.details
            && (!step.collapsible || (step.collapsible && collapsibleState[index]))
            && (index === props.current)
            && <div className="xgs-stepped-progress__step__details">{step.details}</div>
          }
        </li>
      ))}
    </ul>
  );
};

export default SteppedProgress;
