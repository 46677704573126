import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PickupsService from "../../app/data/pickups/pickupsService";
import { initialPickupsState } from "./PickupsState";
import { PickupCompleteModel } from "../../app/data/pickups/models";
import { StopStatus } from "../../app/data/common/constants";
import { ArrivalAtPickupRequest } from "../../app/data/pickups/models";

const pickupsService = PickupsService.getInstance();

export const pickupsSlice = createSlice({
  name: "pickups",
  initialState: initialPickupsState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setPickups: (state, { payload }) => {
      state.pickups = payload;
    },
    addItemToPickup: (state, { payload }) => {
      if (!state.pickups || state.pickups.length === 0) return;
      const newPickups = [...state.pickups];
      const i = newPickups.findIndex(pickup => pickup.pickupNumber === payload.pickupNumber);
      if (i !== -1) {
        newPickups[i].items.push({
          ...payload.item,
          status: "PICKED_UP"
        });
        state.pickups = [...newPickups];
      }
    },
    setItemStatus: (state, { payload }) => {
      if (!state.pickups || state.pickups.length === 0) return;
      const newPickups = [...state.pickups];
      const i = newPickups.findIndex(pickup => pickup.pickupNumber === payload.pickupNumber);
      if (i !== -1) {
        newPickups[i].items[payload.index].status = payload.status;
        state.pickups = [...newPickups];
      }
    },
    setPickupProbill: (state, { payload }) => {
      const i = state.pickupsProbills.findIndex(pickupProbill => pickupProbill.pickupNumber === payload.pickupNumber);
      if (i !== -1) {
        state.pickupsProbills[i].probillNumber = payload.probillNumber;
      } else {
        state.pickupsProbills.push({
          pickupNumber: payload.pickupNumber,
          probillNumber: payload.probillNumber
        })
      }
    },
    setPickupCompleted: (state, { payload }) => {
      if (!state.pickups || state.pickups.length === 0) return;
      const newPickups = [...state.pickups];
      const i = newPickups.findIndex(pickup => pickup.pickupNumber === payload);
      if (i !== -1) {
        newPickups[i].status = StopStatus.COMPLETED;
        const newItems = newPickups[i].items.filter(item => item.status === "PICKED_UP");
        newPickups[i].items = [...newItems];
        state.pickups = [...newPickups];
      }
    },
    updatePickupTotals: (state, { payload }) => {
      if (!state.pickups || state.pickups.length === 0) return;
      const newPickups = [...state.pickups];
      const i = newPickups.findIndex(pickup => pickup.pickupNumber === payload.pickupNumber);
      if (i !== -1) {
        const pickedUpItems = newPickups[i].items.filter(item => payload.updateType === "PICKED_UP" ? (item.status === "PICKED_UP") : item);
        newPickups[i].itemsTotalWeight = 0;
        newPickups[i].itemsCount = 0;
        pickedUpItems.forEach((item) => {
          newPickups[i].itemsTotalWeight += Number(item.weight);
          newPickups[i].itemsCount += 1;
        });
        state.pickups = [...newPickups];
      }
    },
    setPickupReached: (state, { payload }) => {
      if (!state.pickups || state.pickups.length === 0) return;
      const newPickups = [...state.pickups];
      const i = newPickups.findIndex(pickup => pickup.pickupNumber === payload.pickupNumber);
      if (i !== -1) {
        newPickups[i].reached = true;
        newPickups[i].arrivalTime = payload.arrivalTime;
        state.pickups = [...newPickups];
      }
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setPickups,
  addItemToPickup,
  setItemStatus,
  setPickupProbill,
  setPickupCompleted,
  updatePickupTotals,
  setPickupReached
} = pickupsSlice.actions;

export const pickupsSelector = (state: IState) => state.pickups;

export const getPickups = (
  onFailed?: (httpStatusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_PICKUPS"));
  const response = await pickupsService.getPickups();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickups(response.data));
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFailed && onFailed(response.status);
  }
};

export const arriveAtPickup = (
  request: ArrivalAtPickupRequest,
  onSuccess: () => void,
  onFail: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ARRIVE_AT_STOP"))
  const response = await pickupsService.arriveAtPickup(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickupReached(request));
    onSuccess();
  } else {
    if (navigator.onLine) {
      dispatch(requestFailed(response.getError && response.getError()));
      onFail();
    } else {
      dispatch(requestSucceed());
      dispatch(setPickupReached(request));
      onSuccess();
    }
  }
};

export const completePickup = (
  pickupData: PickupCompleteModel,
  onSuccess: () => void,
  onFailed: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("COMPLETE_PICKUP"));
  const response = await pickupsService.completePickup(pickupData);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickupCompleted(pickupData.pickupNumber));
    onSuccess();
  } else {
    if (navigator.onLine) {
      dispatch(requestFailed(response.getError && response.getError()));
      onFailed();
    } else {
      dispatch(requestSucceed());
      dispatch(setPickupCompleted(pickupData.pickupNumber));
      onSuccess();
    }
  }
};

const pickupsReducer = pickupsSlice.reducer;
export default pickupsReducer;