import React from "react";
import { AddressModel } from "../../app/data/common/models";
import Tag from "../../components/tag/tag";
import { TagColor } from "../../app/data/common/tagColor";
import consigneeIcon from "../../images/person_blue.svg";
import probillIcon from "../../images/probill_violet.svg";
import itemIcon from "../../images/item_orange.svg";
import weightIcon from "../../images/weight_gray.svg";
import "./locationHeader.scss";

export interface LocationHeaderProps {
  address: AddressModel;
  probillsCount?: number;
  itemsCount?: number;
  consigneesCount?: number;
  totalWeight?: number;
  type?: string;
  arrivalTime?: string;
}

const LocationHeader: React.FC<LocationHeaderProps> = (props) => {
  return (
    <div className="xgs-location-header">
      <div className="xgs-location-header__top-block">
        <div className="xgs-location-header__address">
          <div className="xgs-location-header__address__value">
            {props.address.address1}, {props.address.city}, {props.address.state}
          </div>
          {props.address.additionalAddressLine && ((props.consigneesCount === 1) || (props.type === "PICKUP")) && (
            <div className="xgs-location-header__address__additional-line">
              <div className="xgs-location-header__address__label">
                Additional Address Details:
              </div>
              <div className="xgs-location-header__address__value">
                {props.address.additionalAddressLine}
              </div>
            </div>
          )}
        </div>
        {props.arrivalTime && (
          <div className="xgs-location-header__arrival">
            <Tag mods={{ color: TagColor.BLUE }}>
              ARRIVED AT {props.arrivalTime.toUiLocalTimeFormat()}
            </Tag>
          </div>
        )}
      </div>
      <div className="xgs-location-header__summary">
        {!!props.consigneesCount && (
          <div className="xgs-location-header__summary__item">
            <div className="xgs-location-header__summary__item__icon xgs-location-header__summary__item__icon--consignees">
              <img src={consigneeIcon} alt="consignee" />
            </div>
            {props.consigneesCount && (
              <div className="xgs-location-header__summary__item__text">
                <span>{props.consigneesCount}</span>
                <br /> Consignee{props.consigneesCount > 1 ? "s" : ""}
              </div>
            )}
          </div>
        )}
        {!!props.probillsCount && (
          <div className="xgs-location-header__summary__item">
            <div className="xgs-location-header__summary__item__icon xgs-location-header__summary__item__icon--probills">
              <img src={probillIcon} alt="probill" />
            </div>
            <div className="xgs-location-header__summary__item__text">
              <span>{props.probillsCount}</span>
              <br /> Probill{props.probillsCount > 1 ? "s" : ""}
            </div>
          </div>
        )}
        {!!props.itemsCount && (
          <div className = "xgs-location-header__summary__item" >
            <div className="xgs-location-header__summary__item__icon xgs-location-header__summary__item__icon--items">
              <img src={itemIcon} alt="item" />
            </div>
            <div className = "xgs-location-header__summary__item__text" >
              <span>{props.itemsCount}</span><br />
              Item{props.itemsCount > 1 ? "s" : ""}
            </div>
          </div>
        )}
        {!!props.totalWeight && (
          <div className="xgs-location-header__summary__item">
            <div className="xgs-location-header__summary__item__icon xgs-location-header__summary__item__icon--weight">
              <img src={weightIcon} alt="weight" />
            </div>
            <div className="xgs-location-header__summary__item__text">
              <span>{props.totalWeight}</span>
              <br /> Pound{props.totalWeight > 1 ? "s" : ""}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationHeader;